import db, {STOCK_STORE} from "@/db/db";

const addStock = async (articles) => {
  try {
    // Map articles array to match the database schema
    const mappedArticles = articles.map(article => ({
      barcode: article.C,
      location: article.L,
      quantity: article.Q,
    }));

    await db[STOCK_STORE].bulkPut(mappedArticles);
  } catch (error) {
    console.error("Failed to add stock:", error);
    throw error;
  }
};

export default addStock;
