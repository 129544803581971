import db, {STOCK_STORE} from "@/db/db";

const getStockForCodeAndLocation = async (code, location) => {
  try {
    return await db[STOCK_STORE]
        .where('[barcode+location]')
        .equals([code, location])
        .toArray();
  } catch (error) {
    console.error("Failed to get stock:", error);
    throw error;
  }
};

export default getStockForCodeAndLocation;
