<template>
    <div class="max-w-md mx-auto my-2 p-3">
        <h1 class="text-2xl font-bold text-center mb-3">Goederen naar winkel</h1>
        <transaction-component
            transactionType="outbound"
        />
    </div>
</template>

<script>

import {defineComponent} from "vue";
import TransactionComponent from "@/components/TransactionComponent.vue";

export default defineComponent({
    components: {TransactionComponent}
})
</script>
