import db, {TRANSACTION_STORE} from "@/db/db";

const markTransactionsAsProcessed = async function (entries) {
  try {
    // Use a transaction for bulk update
    await db.transaction('rw', db[TRANSACTION_STORE], async () => {
      for (let entry of entries) {
        await db[TRANSACTION_STORE].update(entry.id, { processed: 1 });
      }
    });
  } catch (error) {
    console.error("Failed to mark entries as processed:", error);
    throw error; // Rethrow or handle as needed
  }
}

export default markTransactionsAsProcessed;
