<template>
    <div
        v-if="article"
        class="mb-4 "
    >
        <table class="min-w-full divide-y divide-gray-300">
            <thead>
            <tr>
                <th scope="col" colspan="2" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">{{this.article.description}}</th>
            </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
            <tr v-for="stockEntry in stock">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-0">{{ stockEntry.location }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-right">{{ stockEntry.quantity }}</td>
            </tr>
            <tr v-if="stock.length < 1">
                <td colspan="2" class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-0 text-center">Niet in stock</td>
            </tr>
            </tbody>
            <tfoot v-if="totalStock > 0">
            <tr>
                <td class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Totaal</td>
                <td class="py-3.5 pl-4 pr-3 text-right text-sm text-gray-900 sm:pl-0">{{ totalStock }}</td>
            </tr>
            </tfoot>
        </table>

    </div>
</template>

<script>
import getStockForCode from "@/models/stock/getStockForCode";

export default {
    props: {
        article: {
            type: Object,
            false: true
        }
    },
    data(){
        return {
          stock: [],
        }
    },
    computed:{
        totalStock(){
            let total = this.stock.reduce((acc, stockEntry) => acc + parseFloat(stockEntry.quantity), 0);
            // Round to 0 decimals
            return Math.round(total * 100) / 100;
        }
    },
    methods: {
        async getStock() {
            if( !this.article ){
                this.stock = [];
                return;
            }
            this.stock = await getStockForCode(this.article.code)
        }
    },
    watch: {
        article: {
            handler: function (newVal) {
                this.getStock()
            },
            deep: true
        }
    }
}
</script>

