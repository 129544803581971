<template>
    <online-component />
    <router-view />
</template>

<script>
import OnlineComponent from "@/components/OnlineComponent.vue";

export default {
    components: {OnlineComponent},
}
</script>
