import axios from 'axios';
import {AuthService} from "@/services/AuthService";
import fetchUnsyncedTransactions from "@/models/transaction/fetchUnsyncedTransactions";
import markTransactionsAsProcessed from "@/models/transaction/markTransactionsAsProcessed";
import updateLocalStock from "@/models/transaction/updateLocalStock";

const API_URL = process.env.VUE_APP_API_URL; // Your Laravel API URL

export async function syncWithServer() {
  try {
    const transactions = await fetchUnsyncedTransactions();
    const response = await sendTransactionsToServer(transactions);
    // If response is in 200 range, it means the server successfully processed the transactions
    if (response.status >= 200 && response.status < 300) {
      // If the server successfully processes the transactions, mark them as processed
      await markTransactionsAsProcessed(transactions);
      await updateLocalStock(transactions);
    } else {
      // Handle server errors or unsuccessful processing
      console.error("Failed to synchronize transactions with the server.");
    }
  } catch (error) {
    console.error("Synchronization failed", error);
  }
}

async function sendTransactionsToServer(transactions) {
  let token = AuthService.getToken();
  try {
    return await axios.post(`${API_URL}/api/transactions`, {
      transactions
    }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    });
  } catch (error) {
    console.error("Error sending transactions to server:", error.response || error.message);
    throw error; // Rethrow to handle it in the calling function
  }
}
