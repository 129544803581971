import db, {LOCATIONS_STORE} from '../../db/db';

const locationExists =  async function (locationToCheck) {
  try {
    // Use the .where() and .count() methods to find if an entry exists
    const count = await db[LOCATIONS_STORE].where({ location: locationToCheck }).count();
    return count > 0; // If count is greater than 0, the location exists
  } catch (error) {
    console.error("Failed to check location existence:", error);
    throw error;
  }
}

export default locationExists;
