<template>
    <div class="max-w-md mx-auto my-2 p-3">
        <h1 class="text-2xl font-bold text-center mb-3">Artikel info</h1>
        <form @submit.prevent="onSubmit">
            <div class="mb-4">
                <label for="barcode" class="block text-sm font-medium text-gray-700">Code of barcode</label>
                <input
                        id="barcode"
                        type="text"
                        required
                        v-model="code"
                        class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-900 focus:border-blue-900"
                        :class="{ 'border-red-500': !validCode }"
                        @change="codeChanged"
                >
                <div v-if="!validCode" class="text-red-500 text-sm text-left">
                    Deze artikelcode is niet gekend.
                </div>
            </div>

            <article-component
                :article="article"
            />
            <button type="submit" class="w-full bg-blue-900 text-white mb-4 px-4 py-2 rounded hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-900 focus:ring-opacity-50">Toon artikelinfo</button>
            <router-link :to="{ name: 'home' }" class="block text-center w-full bg-gray-600 text-white mb-6 px-4 py-2 rounded hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-opacity-50">Annuleren</router-link>
        </form>

    </div>
</template>

<script>

import {defineComponent} from "vue";
import TransactionComponent from "@/components/TransactionComponent.vue";
import ArticleComponent from "@/components/ArticleComponent.vue";
import getArticleByCode from "@/models/article/getArticleByCode";

export default {
    components: {ArticleComponent},
    data() {
        return {
            article: null,
            code: "",
            validCode: true,
        }
    },
    methods: {
        onSubmit() {
            this.getArticleByCode();
        },
        codeChanged() {
            this.getArticleByCode();
        },
        async getArticleByCode() {
            this.article = await getArticleByCode(this.code);
            this.validCode = !!this.article;
        }
    }

}
</script>
