import Dexie from 'dexie';

export const ARTICLE_STORE = 'articles';
export const LOCATIONS_STORE = 'locations';
export const STOCK_STORE = 'stock';
export const TRANSACTION_STORE = 'transactions';

const db = new Dexie('WMS');
db.version(1).stores({
  [ARTICLE_STORE]: 'code, description, location, unit',
  [LOCATIONS_STORE]: 'location',
  [STOCK_STORE]: '[barcode+location], barcode, location, quantity',
  [TRANSACTION_STORE]: '++id, barcode, quantity, location, processed, type, timestamp',
});

db.version(2).stores({
  [ARTICLE_STORE]: 'code, description, location, unit',
  [LOCATIONS_STORE]: 'location',
  [STOCK_STORE]: '[barcode+location], barcode, location, quantity',
  [TRANSACTION_STORE]: '++id, barcode, quantity, location, processed, type, timestamp, reversed, reversed_transaction_timestamp',
}).upgrade(async (trans) => {
  // Handle the migration of existing data
  const transactions = await trans.table(TRANSACTION_STORE).toArray();
  for (const transaction of transactions) {
    transaction.reversed = null; // Initialize the new field with null or any default value
    await trans.table(TRANSACTION_STORE).put(transaction);
  }
});

export async function clearAllData() {
  try {
    await db.transaction('rw', [ARTICLE_STORE, LOCATIONS_STORE, STOCK_STORE, TRANSACTION_STORE], async () => {
      await Promise.all([
        db[ARTICLE_STORE].clear(),
        db[LOCATIONS_STORE].clear(),
        db[STOCK_STORE].clear(),
        db[TRANSACTION_STORE].clear(),
      ]);
    });
    console.log("All data cleared successfully.");
  } catch (error) {
    console.error("Failed to clear data:", error);
  }
}


export default db;
