import db, {TRANSACTION_STORE} from "@/db/db";

const fetchUnsyncedTransactions = async function () {
  try {
      return await db[TRANSACTION_STORE].where({processed: 0}).toArray();
  } catch (error) {
    console.error("Failed to fetch unsynced entries:", error);
    throw error; // Rethrow or handle as needed
  }
}

export default fetchUnsyncedTransactions;
