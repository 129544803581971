import db, {TRANSACTION_STORE} from "@/db/db";

const deleteTransaction = async (entry) => {
  try {
    return await db[TRANSACTION_STORE].delete(entry.id); // Return the ID of the added entry
  } catch (error) {
    console.error("Failed to add stock entry:", error);
    throw error; // Rethrow or handle as needed
  }
};

export default deleteTransaction;
