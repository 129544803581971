import db, {ARTICLE_STORE} from '../../db/db';

const addArticles = async (articles) => {
  try {
    // Map articles array to match the database schema
    const mappedArticles = articles.map(article => ({
      code: article.C,
      description: article.O,
      location: article.L,
      unit: article.E
    }));

    await db[ARTICLE_STORE].bulkPut(mappedArticles);
  } catch (error) {
    console.error("Failed to add articles:", error);
    throw error;
  }
};

export default addArticles;
