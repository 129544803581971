import axios from 'axios';
import addArticles from "@/models/article/addArticles";
import addLocations from "@/models/location/addLocations";
import addStock from "@/models/stock/addStock";
import {AuthService} from "@/services/AuthService";

const API_URL = process.env.VUE_APP_API_URL; // Your Laravel API URL

export const DataService = {
    getData() {
        this.getLocations();
        this.getArticles();
        this.getStock();
    },

    // Get location from /locations
    async getLocations() {
        const response = await axios.get(`${API_URL}/api/locations`);
        const locations = response.data;
        // Save the locations in indexedDB with dixie
        await addLocations(locations);
    },

    pollForNewData(){
        const pollInterval = 10000; // Poll every 10 seconds
        setInterval(async () => {
            if (AuthService.getToken() && navigator.onLine) {
                try {
                    await this.getData();
                } catch (error) { // Catch block with error argument for debugging
                    console.error('Polling failed', error.message);
                }
            }
        }, pollInterval);
    },

    // Get articles from /articles
    async getArticles() {
        const response = await axios.get(`${API_URL}/api/articles`);
        const articles = response.data;

        await addArticles(articles);
    },

    async getStock() {
        const response = await axios.get(`${API_URL}/api/stock`);
        const stock = response.data;

        await addStock(stock);
    },


};
