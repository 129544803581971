import db, {STOCK_STORE} from "@/db/db";
import getStockForCodeAndLocation from "@/models/stock/getStockForCodeAndLocation";

const changeStock = async (transaction) => {
    try {
        let stock = (await getStockForCodeAndLocation(transaction.barcode, transaction.location))[0];

        if (stock) {

            stock.quantity = parseFloat(stock.quantity) + transaction.quantity;

            if( stock.quantity > 0 ){
                await db[STOCK_STORE].put(stock);
            }
            else{
                await db[STOCK_STORE].delete([stock.barcode, stock.location]);
            }
        } else {
            let stock = {
                barcode: transaction.barcode,
                location: transaction.location,
                quantity: transaction.quantity
            };
            await db[STOCK_STORE].put(stock);
        }
    } catch (error) {
        console.error("Failed to change stock:", error);
        throw error;
    }
};

export default changeStock;
