import db, {TRANSACTION_STORE} from "@/db/db";

const fetchSyncedTransactions = async function () {
  try {
      return await db[TRANSACTION_STORE].where({processed: 1}).toArray();
  } catch (error) {
    console.error("Failed to fetch unsynced entries:", error);
    throw error; // Rethrow or handle as needed
  }
}

export default fetchSyncedTransactions;
