import db, {STOCK_STORE} from "@/db/db";

const getStockForCode = async (code) => {
  try {
    return await db[STOCK_STORE].where("barcode").equals(code).toArray();
  } catch (error) {
    console.error("Failed to get stock:", error);
    throw error;
  }
};

export default getStockForCode;
