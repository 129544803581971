import db, {ARTICLE_STORE} from '../../db/db';

const getArticleByCode = async (code) => {
  try {
    return await db[ARTICLE_STORE].get(code) || null;
  } catch (error) {
    console.error("Failed to get article:", error);
    throw error;
  }
};

export default getArticleByCode;
