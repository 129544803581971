<template>
    <form @submit.prevent="onSubmit">
        <div class="mb-2">
            <label for="barcode" class="block text-sm font-medium text-gray-700">Code of barcode</label>
            <input
                    id="barcode"
                    type="text"
                    required
                    v-model="transaction.barcode"
                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-900 focus:border-blue-900"
                    :class="{ 'border-red-500': !validCode }"
                    @input="capitalize"
                    @change="codeChanged"
            >
            <div v-if="!validCode" class="text-red-500 text-sm text-left">
                Deze artikelcode is niet gekend.
            </div>
        </div>
        <article-component :article="article"/>
        <div class="mb-2">
            <label for="quantity" class="block text-sm font-medium text-gray-700">Aantal</label>
            <input id="quantity" type="number" required min="1" v-model="transaction.quantity" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-900 focus:border-blue-900">
        </div>
        <div class="mb-6">
            <label for="location" class="block text-sm font-medium text-gray-700">Locatie</label>
            <input
                    id="location"
                    type="text"
                    required
                    v-model="transaction.location"
                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-900 focus:border-blue-900"
                    :class="{ 'border-red-500': !validLocation }"
                    @input="capitalize"
            >
            <div v-if="!validLocation" class="text-red-500 text-sm text-left">
                Deze locatie is niet gekend.
            </div>
        </div>
        <div class="mb-6" v-if="transactionType==='move'">
            <label for="location2" class="block text-sm font-medium text-gray-700">Naar locatie</label>
            <input
                    id="location2"
                    type="text"
                    required
                    v-model="location2"
                    class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-900 focus:border-blue-900"
                    :class="{ 'border-red-500': !validLocation2 }"
            >
            <div v-if="!validLocation2" class="text-red-500 text-sm text-left">
                Deze locatie is niet gekend.
            </div>
        </div>

        <button type="submit" class="w-full bg-blue-900 text-white mb-4 px-4 py-2 rounded hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-900 focus:ring-opacity-50">OK</button>
        <router-link :to="{ name: 'home' }" class="text-blue-900 hover:underline">Annuleren</router-link>

        <div v-show="succesMessage" class="fixed inset-0 bg-green-300 bg-opacity-90 flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFFFFF" class="w-80 h-80">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
        </div>
    </form>
</template>

<script>
import {syncWithServer} from "@/services/StockService";
import getArticleByCode from "@/models/article/getArticleByCode";
import addTransaction from "@/models/transaction/addTransaction";
import locationExists from "@/models/location/locationExists";
import ArticleComponent from "@/components/ArticleComponent.vue";
import getStockForCodeAndLocation from "@/models/stock/getStockForCodeAndLocation";


export default {
    components: {ArticleComponent},
    props: {
        transactionType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            transactionDefaultValues: {
                barcode: '', // 00005
                quantity: '',
                location: '', // A.0.A.1.1
                processed: 0,
                timestamp: null,
                type: null,
                reversed: null,
            },
            transaction: {},
            location2: null,
            validLocation2: true,
            articleIn: true,
            validLocation: true,
            validCode: true,
            article: null,
            succesMessage: false,
            isSubmitting: false,
        };
    },
    methods: {
        capitalize(event) {
            event.target.value = event.target.value.toUpperCase();
        },
        async onSubmit() {
            if( this.isSubmitting  ){
                return;
            }
            this.isSubmitting = true;
            this.validLocation = true;
            this.validCode = true;
            try {
                this.article = await getArticleByCode( this.transaction.barcode );
                if( !this.article ) {
                    this.validCode = false;
                    this.isSubmitting = false;
                    return;
                }

                this.validLocation = await locationExists( this.transaction.location );
                if (!this.validLocation) {
                    this.isSubmitting = false;
                    return;
                }

                if( this.transactionType === 'move' ){
                    this.validLocation2 = await locationExists( this.location2 );
                    if (!this.validLocation2) {
                        return;
                    }
                }

                // Set the timestamp
                this.transaction.timestamp = new Date().toISOString();
                const transaction = JSON.parse(JSON.stringify(this.transaction));

                const inStock = (await getStockForCodeAndLocation(transaction.barcode, transaction.location))[0];
                const quantityInStock = inStock?.quantity ?? 0;

                if( !this.articleIn ){
                    transaction.quantity = -transaction.quantity;
                }

                if( this.transactionType === 'stock-correction' ){
                    // Get difference between current stock and new stock
                    transaction.quantity = transaction.quantity - quantityInStock;
                }

                const quantityAfterTransaction = quantityInStock + transaction.quantity;
                if( quantityAfterTransaction < 0 ){
                    alert('Deze actie zou de stock negatief maken. Dit is niet toegestaan.');
                    this.isSubmitting = false;
                    return;
                }

                await addTransaction( transaction );

                if( this.transactionType === 'move' ){
                    const transaction2 = JSON.parse(JSON.stringify(this.transaction));
                    transaction2.location = this.location2;
                    await addTransaction( transaction2 );
                }

                await syncWithServer();
                this.showSuccess();
                this.resetForm();
            } catch (error) {
                this.isSubmitting = false;
                console.error('Failed to save entry', error);
                throw error;
            }
        },
        async codeChanged(){
            this.validCode = true;
            this.article = await getArticleByCode( this.transaction.barcode );

            if( !this.article ) {
                this.validCode = false;
                return;
            }

            if( this.article.location ){
                this.transaction.location = this.article.location;
            }
        },
        showSuccess(){
            // Show success message for 1 second
            this.succesMessage = true;
            setTimeout(() => {
                this.succesMessage = false;
            }, 1000);
        },
        resetForm() {
            this.article = null;
            this.transaction = JSON.parse(JSON.stringify(this.transactionDefaultValues));
            this.location2 = null;
            this.isSubmitting = false;
        },
    },
    created() {
        // Switch statement
        switch (this.transactionType) {
            case 'inbound':
                this.articleIn = true;
                this.transactionDefaultValues.type = 1;
                break;
            case 'return':
                this.articleIn = true; // Terug van brugge naar magazijn
                this.transactionDefaultValues.type = 5;
                break;
            case 'move':
                this.articleIn = false;
                this.transactionDefaultValues.type = 3;
                break;
            case 'outbound':
                this.articleIn = false;
                this.transactionDefaultValues.type = 2;
                break;
            case 'stock-correction':
                this.transactionDefaultValues.type = 4;
                break;
            default:
                console.error('Invalid transaction type');
        }
        this.transaction = JSON.parse(JSON.stringify(this.transactionDefaultValues));
    },
};

</script>
