import './assets/css/main.css';

import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";

import axios from 'axios';
axios.interceptors.request.use(
  config => {
    const token = AuthService.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

import {DataService} from "@/services/DataService";
import {AuthService} from "@/services/AuthService";
// DataService.pollForNewData();

//
// window.addEventListener('beforeinstallprompt', (e) => {
//   e.preventDefault();
//   window.deferredPrompt = e;
//   console.log('Install prompt saved');
// });

createApp(App).use(router).mount("#app");

