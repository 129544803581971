<template>
    <div class="flex flex-col items-center justify-start p-5 h-screen">
        <h1 class="text-1xl font-bold mb-4 text-center">WMS Papyrus</h1>
        <ul class="w-full text-center flex-grow">
            <li class="mb-3">
                <router-link
                    :to="{ name: 'outbound' }"
                    class="inline-block w-full bg-blue-900 text-white py-3 px-4 rounded hover:bg-blue-600 transition duration-150 ease-in-out"
                >Goederen naar winkel</router-link>
            </li>
            <li class="mb-3">
                <router-link
                    :to="{ name: 'inbound' }"
                    class="inline-block w-full bg-blue-900 text-white py-3 px-4 rounded hover:bg-blue-600 transition duration-150 ease-in-out"
                >Nieuw inkomende goederen</router-link>
            </li>
            <li class="mb-3">
                <router-link
                    :to="{ name: 'move' }"
                    class="inline-block w-full bg-blue-900 text-white py-3 px-4 rounded hover:bg-blue-600 transition duration-150 ease-in-out"
                >Wijzigen locatie ZED</router-link>
            </li>
            <li class="mb-3">
                <router-link
                    :to="{ name: 'return' }"
                    class="inline-block w-full bg-blue-900 text-white py-3 px-4 rounded hover:bg-blue-600 transition duration-150 ease-in-out"
                >Retour vanuit Brugge</router-link>
            </li>

            <li class="mb-3">
                <router-link
                    :to="{ name: 'stock-correction' }"
                    class="inline-block w-full bg-blue-900 text-white py-3 px-4 rounded hover:bg-blue-600 transition duration-150 ease-in-out"
                >Voorraad controle</router-link>
            </li>
            <li class="mb-3">
                <router-link
                    :to="{ name: 'article' }"
                    class="inline-block w-full bg-blue-900 text-white py-3 px-4 rounded hover:bg-blue-600 transition duration-150 ease-in-out"
                >Info artikel</router-link>
            </li>
            <li class="mb-3">
                <router-link
                    :to="{ name: 'logs' }"
                    class="inline-block w-full bg-blue-900 text-white py-3 px-4 rounded hover:bg-blue-600 transition duration-150 ease-in-out"
                >Logs</router-link>
            </li>
        </ul>
        <button
            @click="logout"
            class="inline-block w-full bg-red-700 text-white py-3 px-4 rounded hover:bg-blue-600 transition duration-150 ease-in-out"
        >Uitloggen</button>
    </div>

</template>

<script>
import axios from "axios";
import {AuthService} from "@/services/AuthService";
import {clearAllData} from "@/db/db";
import fetchUnsyncedTransactions from "@/models/transaction/fetchUnsyncedTransactions";
const API_URL = process.env.VUE_APP_API_URL; // Your Laravel API URL

export default {
  name: "HomeView",
    created() {
      // check user route
        let token = AuthService.getToken();
        // console.log( token );
        // axios.get(API_URL+'/api/user',
        //     // Set bearer token
        //     {
        //         headers: {
        //             Authorization: `Bearer ${token}`
        //         }
        //     }
        // ).then(response => {
        //     console.log(response.data);
        // }).catch(error => {
        //     console.log(error);
        // });
    },
    methods: {
        async logout() {
            // Confirm the logout
            const unsyncedTransactions = await fetchUnsyncedTransactions();
            let message = 'Weet je zeker dat je wilt uitloggen?';
            if (unsyncedTransactions.length > 0) {
                message = 'Er zijn nog niet gesynchroniseerde transacties. Weet je zeker dat je wilt uitloggen, deze transacties gaan verloren?';
            }
            if (!confirm(message)) {
                return;
            }
            await clearAllData();
            AuthService.logout();
            this.$router.push({ name: 'login' });
        },
        async installPrompt() {
            if (window.deferredPrompt) {
                // Show the install prompt
                window.deferredPrompt.prompt();
                // Wait for the user to respond to the prompt
                const {outcome} = await window.deferredPrompt.userChoice;
                // Optionally, log the choice or do something different depending on the outcome
                console.log(`User response to the install prompt: ${outcome}`);
                // Clear the saved prompt since it can't be used again
                window.deferredPrompt = null;
            }
        }
    },
    mounted(){
        // this.installPrompt();
    }
};
</script>
