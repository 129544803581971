import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import InboundView from "@/views/stock/InboundView.vue";
import ReturnView from "@/views/stock/ReturnView.vue";
import MoveView from "@/views/stock/MoveView.vue";
import OutboundView from "@/views/stock/OutboundView.vue";
import StockCorrectionView from "@/views/stock/StockCorrectionView.vue";
import ArticleView from "@/views/ArticleView.vue";
import LogsView from "@/views/LogsView.vue";
import {AuthService} from "@/services/AuthService";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/inbound",
        name: "inbound",
        component: InboundView,
    },
    {
        path: "/return",
        name: "return",
        component: ReturnView,
    },
    {
        path: "/move",
        name: "move",
        component: MoveView,
    },
    {
        path: "/outbound",
        name: "outbound",
        component: OutboundView,
    },
    {
        path: "/stock-correction",
        name: "stock-correction",
        component: StockCorrectionView,
    },
    {
        path: "/article",
        name: "article",
        component: ArticleView,
    },
    {
        path: "/logs",
        name: "logs",
        component: LogsView,
    },
    {
        path: "/login",
        name: "login",
        component: LoginView,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from) => {
    // Check if user is authenticated
    const isAuthenticated = AuthService.getToken();
    if (
        // make sure the user is authenticated
        !isAuthenticated &&
        // ❗️ Avoid an infinite redirect
        to.name !== 'login'
    ) {
        // redirect the user to the login page
        return { name: 'login' }
    }
})

export default router;
