import db, {TRANSACTION_STORE} from "@/db/db";
import addTransaction from "@/models/transaction/addTransaction";

const reverseTransaction = async (entry) => {
  try {
    const timestamp = new Date().toISOString();
    await db[TRANSACTION_STORE].update(entry.id, { reversed: timestamp });

    let transaction = JSON.parse(JSON.stringify(entry));
    delete transaction.id;
    transaction.type = -transaction.type;
    transaction.quantity = -transaction.quantity;
    transaction.processed = 0;
    transaction.reversed = 0;
    transaction.reversed_transaction_timestamp = entry.timestamp;
    transaction.timestamp = timestamp;
    await addTransaction(transaction);
  } catch (error) {
    console.error("Failed to reverse stock entry:", error);
    throw error; // Rethrow or handle as needed
  }
};

export default reverseTransaction;
