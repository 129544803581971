import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL; // Your Laravel API URL

export const AuthService = {
  getCsrfCookie() {
    return axios.get(`${API_URL}/sanctum/csrf-cookie`);
  },
  async login(user) {
    const response = await axios.post(`${API_URL}/api/login`, user);
    this.storeToken(response.data); // Assuming the token is in the response
    return response;
  },
  storeToken(token) {
    localStorage.setItem('auth_token', token); // Store token in localStorage
  },
  getToken() {
    return localStorage.getItem('auth_token');
  },
  logout() {
    localStorage.removeItem('auth_token'); // Remove token from storage
    delete axios.defaults.headers.common['Authorization']; // Remove auth header
  }
};
