<template>
    <div class="fixed w-full top-0">
        <div v-if="!online" class="bg-red-100 border-b-2 border-red-400 text-red-700 px-4 py-0 text-center" role="alert">
            <span class="block sm:inline">Je bent <strong>offline</strong>!</span>
        </div>
        <div v-else-if="showOnlineStatus" class="bg-green-100 border-b-2 border-green-400 text-green-700 px-4 py-0 text-center" role="alert">
            Je bent terug <strong>online</strong>!
        </div>
    </div>
</template>

<script>

import {syncWithServer} from "@/services/StockService";

export default {
    data(){
        return {
            online: true,
            showOnlineStatus: false,
        }
    },
    methods: {
        async updateOnlineStatus() {
            const oldStatus = this.online;

            this.online = navigator.onLine;

            if (oldStatus === false && this.online === true) {
                this.showOnlineStatus = true;
                setTimeout(() => {
                    this.showOnlineStatus = false;
                }, 5000);
                await syncWithServer();
            }
        }
    },
    created(){
        // Check for online and offline event from browser to set the online status
        window.addEventListener('online',  this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
        this.updateOnlineStatus();
    }
}
</script>

