<template>
    <div>
        <div class="max-w-md mx-auto my-2 p-3">
            <h1 class="text-2xl font-bold text-center mb-3">Logs</h1>

            <div v-if="unsyncedTransactions.length || syncedTransactions.length">
                <router-link :to="{ name: 'home' }" class="block text-center w-full bg-gray-600 text-white mb-4 px-4 py-2 rounded hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-opacity-50 mt-2">Naar home</router-link>
            </div>

            <div v-if="unsyncedTransactions.length === 0 && syncedTransactions.length === 0">
                <p class="text-center">Geen logs beschikbaar</p>
            </div>

            <div v-if="unsyncedTransactions.length" class="bg-red-100 p-3 mb-3">
                <h1 class="text-1xl font-bold text-center mb-6 text-red-400">Niet gesynchroniseerd</h1>
                <ul>
                    <li
                        v-for="transaction in unsyncedTransactions" :key="transaction.id"
                        class="mb-3"
                    >
                        <div class="flex">
                            <div class="flex-grow">
                                <div class="flex justify-between mb-1">
                                    <div class="font-semibold leading-6 text-gray-900">
                                        {{ transaction.location }} | {{ transaction.barcode }}
                                    </div>
                                    <div>
                                        {{ transaction.quantity }}
                                    </div>
                                </div>
                                <div class="text-gray-400">
                                    {{ showDate(transaction.timestamp) }}
                                </div>
                                <div
                                    v-if="transaction.type < 0"
                                    class="text-gray-400"
                                >
                                    <span class="text-red-500">Eerdere transactie terugdraaien</span>
                                </div>
                            </div>
                            <div class="ml-5">
                                <button
                                    @click="deleteTransaction(transaction)"
                                    :disabled="transaction.type < 0"
                                    class="inline-block w-full bg-red-700 text-white py-2 px-3 rounded hover:bg-blue-600 transition duration-150 ease-in-out disabled:opacity-75 disabled:bg-gray-400"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                    </svg>
                                </button>
                            </div>
                        </div>

                    </li>
                </ul>
            </div>

            <div v-if="syncedTransactions.length" class="p-3">
                <h1 class="text-1xl font-bold text-center mb-6">Gesynchroniseerd</h1>
                <ul>
                    <li
                        v-for="transaction in syncedTransactions" :key="transaction.id"
                        class="mb-3"
                    >
                        <div class="flex">
                            <div class="flex-grow">
                                <div class="flex justify-between mb-1">
                                    <div class="font-semibold leading-6 text-gray-900">
                                        {{ transaction.location }} | {{ transaction.barcode }}
                                    </div>
                                    <div>
                                        {{ transaction.quantity }}
                                    </div>
                                </div>
                                <div class="text-gray-400">
                                    {{ showDate(transaction.timestamp) }}
                                </div>
                                <div
                                    v-if="transaction.type < 0"
                                    class="text-gray-400"
                                >
                                    <span class="text-red-500">Teruggedraaide transactie</span>
                                </div>
                                <div
                                    v-if="transaction.reversed"
                                    class="text-gray-400"
                                >
                                    <span class="text-red-500">Teruggedraaid op {{ showDate(transaction.reversed) }}</span>
                                </div>
                            </div>
                            <div class="ml-5">
                                <button
                                    @click="reverseTransaction(transaction)"
                                    :disabled="transaction.type < 0 || transaction.reversed"
                                    class="inline-block w-full bg-red-700 text-white py-2 px-3 rounded hover:bg-blue-600 transition duration-150 ease-in-out disabled:opacity-75 disabled:bg-gray-400"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                      <path stroke-linecap="round" stroke-linejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
                                    </svg>
                                </button>
                            </div>
                        </div>

                    </li>
                </ul>
            </div>
            <router-link :to="{ name: 'home' }" class="block text-center w-full bg-gray-600 text-white mb-4 px-4 py-2 rounded hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-700 focus:ring-opacity-50 mt-2">Naar home</router-link>
        </div>
    </div>
</template>

<script>

import fetchSyncedTransactions from "@/models/transaction/fetchSyncedTransactions";
import fetchUnsyncedTransactions from "@/models/transaction/fetchUnsyncedTransactions";
import reverseTransaction from "@/models/transaction/reverseTransaction";
import {syncWithServer} from "@/services/StockService";
import deleteTransaction from "@/models/transaction/deleteTransaction";

export default {
    data() {
        return{
            syncedTransactions: [],
            unsyncedTransactions: [],
        }
    },
    methods:{
        showDate(date){
            return new Intl.DateTimeFormat('nl-BE', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
            }).format(new Date(date));
        },
        async getTransactions() {
            this.syncedTransactions = await fetchSyncedTransactions();
            // Order on timestamp desc
            this.syncedTransactions.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
            this.unsyncedTransactions = await fetchUnsyncedTransactions();
            // Order on timestamp desc
            this.unsyncedTransactions.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
        },
        async deleteTransaction(transaction){
            confirm('Weet je zeker dat je deze transactie van '+this.showDate(transaction.timestamp)+' wil verwijderen?');
            await deleteTransaction(transaction);
            await this.getTransactions();
        },
        async reverseTransaction(transaction){
            confirm('Weet je zeker dat je deze transactie van '+this.showDate(transaction.timestamp)+' wil terugdraaien?');
            await reverseTransaction(transaction);
            await syncWithServer();
            await this.getTransactions();
        }
    },
    created() {
        this.getTransactions();
    },
}
</script>
