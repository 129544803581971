<template>
    <form @submit.prevent="authenticate" class="mx-auto p-5">
        <h1 class="text-2xl font-bold mb-6 text-center">WMS</h1>
        <div class="mb-6">
            <label for="login" class="block mb-2 text-sm font-medium text-gray-700">Login</label>
            <input id="login" v-model="login" type="text" placeholder="Login" required class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-900 focus:border-blue-900 placeholder-gray-400">
        </div>
        <div class="mb-6">
            <label for="pin" class="block mb-2 text-sm font-medium text-gray-700">PIN</label>
            <input id="pin" v-model="pin" type="password" placeholder="PIN" autocomplete="off" required class="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-900 focus:border-blue-900 placeholder-gray-400">
        </div>
        <div class="flex justify-center mb-4">
            <button type="submit" class="w-full bg-blue-900 text-white px-4 py-2 rounded hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-900 focus:ring-opacity-50">Login</button>
        </div>
        <div v-if="invalidLogin" class="text-red-500 text-sm text-center">
            Invalid login or PIN
        </div>
    </form>

</template>

<script>
import { AuthService } from '@/services/AuthService';
import {DataService} from "@/services/DataService";

export default {
    data() {
        return {
            login: '',
            pin: '',
            invalidLogin: false,
        };
    },
    methods: {
        async authenticate() {
            this.invalidLogin = false;
            try {
                await AuthService.getCsrfCookie();
                await AuthService.login({
                    login: this.login,
                    pin: this.pin,
                });

                await DataService.getData();

                this.$router.push({ name: 'home' }); // Navigate to the dashboard or home page
            } catch (error) {
                this.invalidLogin = true;
                this.pin = '';
            }
        },
    },
};
</script>
