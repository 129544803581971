import db, {LOCATIONS_STORE} from '../../db/db';

const addLocations = async (locations) => {
  try {
    const locationObjects = locations.map(location => ({ location }));
    await db[LOCATIONS_STORE].bulkPut(locationObjects);
  } catch (error) {
    console.error("Failed to add location:", error);
    throw error; // Rethrow or handle as needed
  }
};

export default addLocations;
